const THEME: Record<string, string> = {
  colorBackgroundButtonNormalDefault: '#32292e',
  colorBackgroundButtonNormalHover: '#2c2126',
  colorBackgroundButtonLinkHover: '#2c2126',
  colorBackgroundButtonPrimaryActive: '#d65b43',
  colorBackgroundButtonPrimaryDefault: '#d65b43',
  colorBackgroundButtonPrimaryHover: '#d65b43',
  colorBackgroundContainerContent: '#32292e',
  colorBackgroundContainerHeader: '#2c2126',
  colorBackgroundDropdownItemDefault: '#170f13',
  colorBackgroundDropdownItemHover: '#4f4048',
  colorBackgroundDropdownItemSelected: '#3b1226',
  colorBackgroundInputDefault: '#281a21',
  colorBackgroundItemSelected: '#3b1226',
  colorBackgroundLayoutMain: '#1e151a',
  colorBackgroundLayoutPanelContent: '#32292e',
  colorBackgroundLayoutPanelHover: '#4f4047',
  colorBorderButtonPrimaryHover: '#c72100',
  colorBorderDividerDefault: '#4f4047',
  colorBorderDropdownItemDefault: '#4f4048',
  colorBorderDropdownItemHover: '#968a87',
  colorBorderDropdownItemSelected: '#c92200',
  colorBorderDropdownItemTop: '#332a2e',
  colorBorderInputDefault: '#96878f',
  colorBorderInputDisabled: '#4f4048',
  colorBorderItemFocused: '#bf5540',
  colorBorderItemSelected: '#c72100',
  colorTextAccent: '#bf5540',
  colorTextInteractiveDisabled: '#786870',
  colorTextLabel: '#a79895',
  colorTextLinkDefault: '#bf5540',
  colorTextSmall: '#a79895',
};

export default THEME;
